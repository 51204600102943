export const PITCH_DECK = 'https://docsend.com/view/6tnfzez7ajzaqn47'
export const EXECUTIVE_SUMMARY = 'https://docsend.com/view/9hb3pvhcrcwpb3gf'
export const PODCASTS =
  'https://intuition-systems.notion.site/108450d37d06808fbd43f863e3daca22?v=13c450d37d0680f499eb000c7b7ffa90'
export const ECOSYSTEM = 'https://docsend.com/view/eh2d4pbazmmreihd'
export const WHITE_PAPER = 'https://docsend.com/view/zjaxy5xhpswzi94e'
export const LITE_PAPER = 'https://docsend.com/view/mw8sp8gcjxv7jx5r'
export const DOCS = 'https://docsend.com/view/82629acby9fuu9fw'
export const TOKENOMICS = 'https://docsend.com/view/bgnav9ws4xc7ebg5'
export const FINANCIAL_MODEL = 'https://docsend.com/view/s6bfcrr6ji7mq2e9'
export const CAP_TABLE = 'https://docsend.com/view/35jrs6zu8y282kdw'
export const LEGAL_DOCS = 'https://docsend.com/view/s/m682vc5yi3uipi5h'
export const GROWTH_STRATEGY = 'https://docsend.com/view/u6jrtsd58jd9b8dr'
export const MEDIA_MENTIONS =
  'https://intuition-systems.notion.site/108450d37d06808fbd43f863e3daca22?v=13c450d37d0680f499eb000c7b7ffa90'
export const SCHEDULE_A_CALL = 'https://docsend.com/view/mkffsbvv6gnpnmap'
export const CONTACT_US = 'https://docsend.com/view/sex5mr2cqkzkuqvg'
export const BORING_DOCSEND = 'https://docsend.com/view/s/ykfspw336e2c8yrs'
